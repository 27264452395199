/* @media screen and (min-width: 500px) and (max-width: 600px) {
  .sign-up-form-wrapper {
    width: 90vw;
     padding: 80px 80px;
   }
 }

 @media screen and (max-width: 499px) {
  .sign-up-form-wrapper  {
     width: 100vw;
     padding: 80px 20px;
  }
  .sign-up-form-content-title {
     font-size: 18px;
  }
  .sign-up-form-content-form-box {
    width: calc(100% + 13px);
    padding-right: 8px;
  }
 }

 @media screen and (max-width: 400px) {
  .sign-up-form-content-form-looking-to-div  {
    width: 100%
  }
 } */

 @media screen and (min-width: 500px) and (max-width: 600px) {
  .sign-up-form-wrapper {
     width: 500px;
     /* height: 700px; */
     padding: 80px 90px;
   }
   .sign-up-form-content-form-input-error-captcha {
    top: 84px;
  }
 }

 @media screen and (max-width: 500px) {
  .sign-up-form-content-form-box {
    width: calc(100% + 13px);
    padding-right: 7px;
  }
 }
 
 @media screen and (min-width: 360px) and (max-width: 499px) {
    .sign-up-form-wrapper {
       width: 360px;
       padding: 80px 20px;
     }
     .sign-up-form-content-title {
       font-size: 18px;
    }
   }
 
 @media screen and (max-width: 359px) {
  .sign-up-form-wrapper {
     width: 100vw;
     padding: 80px 20px;
  }
  .sign-up-form-content-title {
     font-size: 18px;
  }
 }
 
