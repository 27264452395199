.login-box-wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 1052;
  padding: 100px 125px;
  flex-direction: column;
  display: flex;
}

  /* input placeholder color */
  .login-box-wrapper input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    var(--gray)!important;
  }
  .login-box-wrapper input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    var(--gray)!important;
    opacity:  1;
  }
  .login-box-wrapper input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:   var(--gray)!important;
    opacity:  1;
  }
  .login-box-wrapper input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:   var(--gray)!important;
  }
  .login-box-wrapper input::-ms-input-placeholder { /* Microsoft Edge */
    color:   var(--gray)!important;
  }

  .login-box-wrapper input::placeholder { /* Most modern browsers support this now. */
    color:   var(--gray)!important;
  }