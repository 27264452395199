.signOut-box-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 128px;
  width: 300px;
  position: absolute;
  background-color: var(--main-bg);
  color: var(--body-text);
  padding: 10px;
  font-family: inter;
  z-index: 1052;
}

.signOut-box-wrapper-header-sign-out {
  cursor: pointer;
}

.signOut-box-wrapper-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.signOut-box-wrapper-content {
  display: flex;
  gap: 14px
}

.signOut-box-wrapper-content-avatar {
  width: 88px;
  height: 88px;
  border-radius: 50%;
  border: 2px solid var(--blue);
  /* background-image: url('https://rtcdn2.azureedge.net/public/assets/images/customer.png');  */
  background-size: cover; /* Adjust the size property as needed */
  background-position: center center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent background image from repeating */
}

.signOut-box-wrapper-content-main {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: calc(100% - 102px);
}

.signOut-box-wrapper-content-main-name p {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow:    ellipsis;
}

.signOut-box-wrapper-content-main-email p {
  font-size: 12px;
  font-weight: 700;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow:    ellipsis;
}

.signOut-box-wrapper-content-main-link a {
  font-size: 13px;
  color: var(--blue);
  border-bottom: 1px solid var(--blue);
  cursor: pointer;
}
