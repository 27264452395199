.login-form-wrapper {
  width: 570px;
  /* height: 700px; */
  position: fixed;
  background-color: var(--main-bg);
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  border-radius: 10px;
  padding: 80px 125px;
  z-index: 1052;
  left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

.login-form-logo-wrapper {
  position: absolute;
  left: 24px;
  top: 24px
}

.login-form-close-icon-wrapper {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.login-form-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.login-form-content-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 31.47px;
  color:var(--white);
  padding-bottom: 26px;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 32px;
  width: auto;
  text-align: center;
}

.login-form-alternative-block,
.login-form-content-form  {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px
}

.login-form-content-or {
  margin: 16px 0;
  color:var(--light-gray);
  font-size: 12px;
  opacity: 0.7;
}

.login-form-content-form-forgot-password {
  color: var(--body-text);
  margin-bottom: -16px;
}

.login-form-content-form-forgot-password span {
  cursor: pointer;
}

.login-form-alternative-block-need-acc {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.login-form-alternative-block-need-acc span {
  font-size: 16px;
  color: var(--body-text);
  width: 100%;
}

.login-form-alternative-block-need-acc .login-form-alternative-block-sign-up {
  color: var(--blue);
  cursor: pointer;
}

.login-form-content-form input {
  font-size: 14px;
}

  .login-form-content-form-input-wrapper {
    position: relative;
  }

  .login-form-content-form-input-error {
    position: absolute;
    top: 53px;
    display: flex;
    justify-content: center;
  }

  .login-form-content-form-input-error-captcha {
    position: absolute;
    top: 82px;
    display: flex;
    justify-content: center;
  }

  .login-form-content-form-input-error-captcha p,
  .login-form-content-form-input-error p{
    margin-left: 5px;
    margin-top: 2px;
    color: var(--red);
    font-size: 12px;
  }
