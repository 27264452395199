.forgot-pass-form-wrapper {
  width: 570px;
  /* height: 470px; */
  position: fixed;
  background-color: var(--main-bg);
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  border-radius: 10px;
  padding: 80px 125px;
  z-index: 1052;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.forgot-pass-form-logo-wrapper {
  position: absolute;
  left: 24px;
  top: 24px
}

.forgot-pass-form-close-icon-wrapper {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.forgot-pass-form-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.forgot-pass-form-content-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 31.47px;
  color:var(--white);
  padding-bottom: 26px;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 32px;
  width: auto;
  text-align: center;
}

.forgot-pass-form-content-form  {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  position: relative;
}

.forgot-pass-content-form-input-error {
  position: absolute;
  top: 53px;
  display: flex;
  justify-content: center;
}

.forgot-pass-content-form-input-error p {
  margin-left: 5px;
  margin-top: 2px;
  color: var(--red);
  font-size: 12px;
}

.login-form-content-form-input-error-captcha {
  position: absolute;
  top: 82px;
  display: flex;
  justify-content: center;
}

.login-form-content-form-input-error-captcha p {
  margin-left: 5px;
  margin-top: 2px;
  color: var(--red);
  font-size: 12px;
}

.forgot-pass-form-content-or {
  margin: 16px 0;
  color:var(--light-gray);
  font-size: 12px;
  opacity: 0.7;
}

.forgot-pass-form-content-form-forgot-password {
  color: var(--body-text);
  margin: -4px 0 -16px 0;
}

.forgot-pass-form-content-form-forgot-password span {
  cursor: pointer;
}

.forgot-pass-form-alternative-block-back {
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
}

.forgot-pass-form-alternative-block-back span {
  color: var(--blue);
  cursor: pointer;
}

.forgot-pass-form-content-form input {
  font-size: 14px;
}

.forgot-pass-form-content-form input::-webkit-input-placeholder { /* WebKit browsers */
    color: var(--gray)
  }
.forgot-pass-form-content-form input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: var(--gray)
  }
.forgot-pass-form-content-form input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--gray)
  }
.forgot-pass-form-content-form input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: var(--gray)
  }
