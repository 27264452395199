@media screen and (min-width: 500px) and (max-width: 600px) {
 .login-form-wrapper {
    width: 500px;
    /* height: 700px; */
    padding: 80px 90px;
  }
  .login-form-wrapper form .base-button-container {
   margin-top: 5px;
  }
  .login-form-content-form-input-error-captcha {
   top: 84px;
 }
 .login-form-wrapper .login-form-content-form >div:last-child {
   margin-top: -5px !important;
 }
}

@media screen and (min-width: 360px) and (max-width: 499px) {
   .login-form-wrapper {
      width: 360px;
      padding: 80px 20px;
    }
    .login-form-content-title {
      font-size: 18px;
   }
  }

@media screen and (max-width: 359px) {
 .login-form-wrapper {
    width: 100vw;
    padding: 80px 20px;
 }
 .login-form-content-title {
    font-size: 18px;
 }
}
