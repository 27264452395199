body {
  font-family: Inter;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --black: black;
  --main-bg:   #141313;
  --second-bg:#2B2A2A;
  --body-text: #F2F2F2;
  --second-body-text: rgba(242, 242, 242, 0.80);
  --gray: #908F8F;
  --light-gray: #f9f9f9;
  --blue: #2078BD;
  --white: #FFFFFF;
  --third-bg: #E9E9E9;
  --red: #FF221C;
  --green: #38c172;
  --yellow: #FFC805;
  --second-yellow: #FFBF44;
  --light-br: #908f8f45;
}
