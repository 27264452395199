@media screen and (min-width: 500px) and (max-width: 600px) {
  .confirm-message-wrapper {
     width: 500px;
     padding: 80px 90px;
   }
   .confirm-message-wrapper form .base-button-container {
    margin-top: 5px;
   }
   .confirm-message-content-form-input-error-captcha {
    top: 84px;
  }
  .confirm-message-wrapper .confirm-message-content-form >div:last-child {
    margin-top: -5px !important;
  }
 }
 
 @media screen and (min-width: 360px) and (max-width: 499px) {
    .confirm-message-wrapper {
       width: 360px;
       padding: 80px 20px;
     }
     .confirm-message-content-title {
       font-size: 18px;
    }
   }
 
 @media screen and (max-width: 359px) {
  .confirm-message-wrapper {
     width: 100vw;
     padding: 80px 20px;
  }
  .confirm-message-content-title {
     font-size: 18px;
  }
 }
 