.confirm-message-logIn-wrapper {
  position: fixed;
  width: 570px;
  background-color: var(--main-bg);
  border-radius: 10px;
  padding: 80px;
  z-index: 1052;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.confirm-message-logIn-wrapper .confirm-message-logIn-content-text-code-input-box input{
  border-radius: 10px;
}

.confirm-message-logIn-form-logo-wrapper {
  position: absolute;
  left: 24px;
  top: 24px
}

.confirm-message-logIn-content-input-box {
  width: 100%;
}

.confirm-message-logIn-content-text-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.confirm-message-logIn-close {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.confirm-message-logIn-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.confirm-message-logIn-content-text-code,
.confirm-message-logIn-content-text-bottom,
.confirm-message-logIn-content-header,
.confirm-message-logIn-content-text {
  text-align: center;
}

.confirm-message-logIn-content-header span{
  font-weight: 600;
  font-size: 22px;
  color: var(--body-text);
}

.confirm-message-logIn-content-text {
  margin-top: 10px;
}
.confirm-message-logIn-content-text span {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--body-text);
}

.confirm-message-logIn-content-text-bottom span {
  font-weight: 400;
  font-size: 12px;
  line-height: 16.94px;
  color: var(--gray) !important;
}

.confirm-message-logIn-content-text-code span {
  font-weight: 400;
  font-size: 13px;
  line-height: 16.94px;
  color: var(--body-text);
}

/* .confirm-message-logIn-content-text-code-input {
  width: 80px
} */

.confirm-message-logIn-content-text-code-input-box {
  display: flex;
  justify-content: center;
}

.confirm-message-logIn-content-text-code-input-box input {
  font-size: 22px;
  padding: 10px !important;
  text-align: center;
}

.confirm-message-logIn-content-input-box input {
  font-size: 18px;
  text-align: center;
}

.confirm-message-logIn-content-text-bottom span{
  cursor: pointer;
}

.confirm-message-logIn-content-input-box-disabled input{
  color: var(--gray)!important;
}

.confirm-message-logIn-content-text-submit {
  margin-top: 15px;
}

.confirm-message-logIn-content-form-input-error {
  position: absolute;
  top: 53px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.confirm-message-logIn-content-form-input-error-2 p,
.confirm-message-logIn-content-form-input-error p {
  margin-left: 5px;
  margin-top: 2px;
  color: var(--red);
  font-size: 12px;
}

.confirm-message-logIn-content-form-input-error-2 {
  position: absolute;
  top: 43px;
  display: flex;
  justify-content: center;
}

.confirm-message-logIn-content-input-box,
.confirm-message-logIn-content-text-code-input-box {
  position: relative;
}