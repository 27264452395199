.filters-page-base-input-list-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.filters-page-base-input-list-end-icon-wrapper {
  height: 22px;
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.filters-page-base-input-list-wrapper input {
  border-radius: 10px;
  border: 1px solid var(--gray);
  cursor: pointer;
  height: 100%;
}


.filters-page-base-input-list-wrapper input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--gray);
  outline: none!important;
  box-shadow: none;
}

.filters-page-base-input-list-wrapper input:read-only {
  background-color: var(--white);
}

/* .filters-page-base-input-list-wrapper input::-webkit-input-placeholder {
  color: var(--main-bg);
}

.filters-page-base-input-list-wrapper input:-ms-input-placeholder {
  color: var(--main-bg);
}

.filters-page-base-input-list-wrapper input::placeholder {
  color: var(--main-bg);
} */

/* LIST STyle */

.filters-page-base-input-list-wrapper ul {
  position: absolute;
  list-style-type: none;
  top: 54px;
  padding: 0;
  margin: 0;
  z-index: 5;
  width: 540px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--gray);
  background-color: var(--white);
  /* display: flex; */
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  max-height: 220px;
  overflow-y: auto;
  display: none;
  width: 100%
}

.filters-page-base-input-list-wrapper ul li:last-child p {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.filters-page-base-input-list-wrapper ul > li > p {
  background-color: var(--white);
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: inter;
  color: var(--main-bg);
  margin-bottom: 0;
}

.filters-page-base-input-list-wrapper ul li p:hover {
  background-color: var(--blue) !important;
  color: var(--body-text) !important;
  cursor: pointer;
  opacity: 1;
}

.base-input-list-field2-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: inter;
  color: var(--main-bg);
  margin-bottom: 0;
}

/* .filters-page-base-input-list-wrapper ul li:first-child {
  border-bottom: 1px solid var(--gray);
} */

.filters-page-base-input-list-wrapper ul li:first-child > div:hover {
  background-color: var(--body-text);
  color: inherit;
}

.filters-page-base-input-list-wrapper ul li > div:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
  opacity: 1;
}

.filters-page-base-input-list-wrapper ul li > div:hover .base-input-list-field2-inactive,
.filters-page-base-input-list-wrapper ul li > div:hover .base-input-list-field2-active{
  color: var(--body-text);
}

.base-input-list-field2-active {
  color: var(--green);
}
.base-input-list-field2-inactive {
  color: var(--red);
}

/* .base-input-list-field2-wrapper {
  position: absolute;
  top:13px;
  right: 12px;
}

.base-input-list-field2-wrapper p {
  font-size: 12px;
}

.filters-page-base-input-list-wrapper:hover .base-input-list-field2-wrapper p {
  color: var(--body-text);
} */

.filters-page-my-saved-searches-form-top-btns {
  display: flex;
  width: 100%;
}

.filters-page-my-saved-searches-form-top-btns-edit {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.filters-page-my-saved-searches-form-top-btns-new {
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 8px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  font-family: inter;
  /* color: var(--main-bg); */
  margin-bottom: 0;
  position: relative;
  font-weight: 600;
  color: var(--lg-second-bg);
}

.filters-page-my-saved-searches-form-top-btns-new-add {
  padding: 4px;
  border-radius: 4px;
  background-color: var(--blue);
  margin-right: 6px;
  color: var(--body-text);
}

.filters-page-my-saved-searches-form-top-btns-li {
  /* display: none */
}
