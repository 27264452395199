.sign-up-form-content-box {
  display: flex;
  flex-direction: column;
  gap: 34px;
  width: 100%;
}

.sign-up-form-wrapper {
  width: 570px;
  position: fixed;
  background-color: var(--main-bg);
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  border-radius: 10px;
  padding: 80px 125px 50px 125px;
  z-index: 1052;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.sign-up-form-logo-wrapper {
  position: absolute;
  left: 24px;
  top: 24px
}

.sign-up-form-close-icon-wrapper {
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;
}

.sign-up-form-content-form-box {
  width: calc(100% + 16px);
  max-height: 320px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-right: 10px;
  margin-bottom: -30px;
}



/* .sign-up-form-content-form-contact-info .sign-up-content-form-input-wrapper:last-child  {
  margin-bottom: 22px;
} */

.sign-up-form-content-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.sign-up-form-content-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 31.47px;
  color:var(--white);
  padding-bottom: 26px;
  border-bottom: 1px solid var(--gray);
  margin-bottom: 32px;
  width: auto;
}

.sign-up-form-content-form  {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px
}

.sign-up-form-content-or {
  margin: 16px 0;
  color:var(--light-gray);
  font-size: 12px;
  opacity: 0.7;
}

.sign-up-form-content-form-forgot-password {
  color: var(--body-text);
  margin: -4px 0 -16px 0;
}

.sign-up-form-content-form-forgot-password span {
  cursor: pointer;
}

.sign-up-form-alternative-block {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.sign-up-form-alternative-block span {
  font-size: 16px;
  color: var(--body-text);
  width: 100%;
}

.sign-up-form-alternative-block-back span {
  color: var(--blue);
  cursor: pointer;
}

.sign-up-form-content-form input {
  font-size: 14px;
}

.sign-up-form-content-form input::-webkit-input-placeholder { /* WebKit browsers */
    color: var(--gray)!important
  }
.sign-up-form-content-form input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: var(--gray)!important
  }
.sign-up-form-content-form input::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: var(--gray)!important
  }
.sign-up-form-content-form input:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: var(--gray)!important
  }

.sign-up-form-content-form-looking-in,
.sign-up-form-content-form-looking-to,
.sign-up-form-content-form-password-info,
.sign-up-form-content-form-confirm-password-info,
.sign-up-form-content-form-property-type,
.sign-up-form-content-form-contact-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sign-up-form-content-form-contact-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sign-up-form-content-form-contact-info > div:nth-of-type(1) {
  margin-bottom: -16px;
}

.form-box-title span {
  color: var(--body-text);
  font-size: 16px;
}

.sign-up-form-content-form-looking-to-box {
  display: flex;
  flex-wrap: wrap;
  gap: 16px
}

.sign-up-form-content-form-looking-to-div {
  width: calc(50% - 8px);
  height: 43px;
  border: none;
  cursor: pointer;
  background-color: var(--second-bg);
  color: var(--body-text);
  border-radius: 10px;
  font-size: 14px!important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-up-content-form-input-wrapper {
  position: relative;
}

.sign-up-content-form-input-error {
  position: absolute;
  top: 53px;
  display: flex;
  justify-content: center;
}

.sign-up-content-form-input-error p{
  margin-left: 5px;
  margin-top: 2px;
  color: var(--red);
  font-size: 12px;
}

/*
 *  SCROLLBAR STYLE 10
 */
 
.sign-up-form-content-form-box::-webkit-scrollbar {
  width: 6px;
}

.sign-up-form-content-form-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 10px
}

.sign-up-form-content-form-box::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--gray);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
  }

.sign-up-form-content-form-box::-webkit-scrollbar-thumb:window-inactive {
  background: var(--second-bg)
  }

  .login-form-content-form-input-wrapper {
    position: relative;
  }

  /* .sign-up-content-form-password {
    margin-bottom: 19px;
  } */


  .sign-up-form-content-form-confirm-password-info .base-input-container {
    margin-bottom: 24px;
  }

/* LIST SCROLLBAR STYLE */

.filters-page-base-input-list-wrapper ul {
  background-color: #111111!important
  }

  .filters-page-base-input-list-wrapper ul::-webkit-scrollbar{
    width: 6px;
  }
    
  .filters-page-base-input-list-wrapper ul::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 10px
  }
  
  .filters-page-base-input-list-wrapper ul::-webkit-scrollbar-thumb{
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--gray);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
  
  .filters-page-base-input-list-wrapper ul::-webkit-scrollbar-thumb:window-inactive {
    background: var(--second-bg)
    }