/* @media screen and (min-width: 500px) and (max-width: 600px) {
  .forgot-pass-form-wrapper {
     width: 95vw;
     padding: 80px 80px;
   }
 }
 
 @media screen and (max-width: 499px) {
  .forgot-pass-form-wrapper  {
     width: 100vw;
     padding: 80px 20px;
  }
  .forgot-pass-form-content-title {
     font-size: 18px;
  }
 } */

 @media screen and (min-width: 500px) and (max-width: 600px) {
   .forgot-pass-form-wrapper {
      width: 500px;
      padding: 80px 90px;
    }
    .forgot-pass-form-wrapper form .base-button-container {
     margin-top: 5px;
    }
    .forgot-pass-form-content-form-input-error-captcha {
     top: 84px;
   }
  }
  
  @media screen and (min-width: 360px) and (max-width: 499px) {
     .forgot-pass-form-wrapper {
        width: 360px;
        padding: 80px 20px;
      }
      .forgot-pass-form-content-title {
        font-size: 18px;
     }
    }
  
  @media screen and (max-width: 359px) {
   .forgot-pass-form-wrapper {
      width: 100vw;
      padding: 80px 20px;
   }
   .forgot-pass-form-content-title {
      font-size: 18px;
   }
  }